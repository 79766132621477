import {
  ChartDataType,
  ExploreField,
  ExploreVisualizationType,
  HqlAggregationFunction,
  calciteTypeToChartDataType,
  getFieldActiveScaleType,
} from "@hex/common";

export function getSimpleType(
  field: ExploreField,
  vizType: ExploreVisualizationType,
): ChartDataType {
  if (vizType === "pivot-table") {
    return calciteTypeToChartDataType(field.dataType);
  }
  return getFieldActiveScaleType(field);
}

export const stringAggregations: HqlAggregationFunction[] = [
  "Count",
  "CountDistinct",
];
export const datetimeAggregations: HqlAggregationFunction[] = [
  "Count",
  "CountDistinct",
  "Median",
  "Min",
  "Max",
];
export const numberAggregations: HqlAggregationFunction[] = [
  "Sum",
  "Count",
  "Avg",
  "Min",
  "Max",
  "CountDistinct",
  "Median",
  "StdDev",
  "StdDevPop",
  "Variance",
  "VariancePop",
];
