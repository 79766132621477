import {
  CellId,
  HexVersionAtomicOperation,
  UPDATE_EXPLORE_CELL,
} from "@hex/common";
import { isEqual } from "lodash";
import { useCallback } from "react";

import { DispatchAOResult } from "../../atomic-operations";
import { useCellContentsGetter } from "../../hex-version-multiplayer/state-hooks/cellContentsStateHooks.js";
import { useLockStatus, useUpdateLock } from "../../hooks/locks/useLock.js";
import { useProjectVersionEditable } from "../../hooks/sessionOrProjectEditableHooks.js";
import { useCellContext } from "../../util/cellContext.js";
import { useHexVersionAOContext } from "../../util/hexVersionAOContext.js";
import {
  ExploreSpecUpdateActions,
  exploreSpecReducer,
} from "../data/editExploreSpecReducer.js";

interface UpdateExploreSpecOptions {
  suppressAutoRun?: boolean;
  additionalActions?: HexVersionAtomicOperation[];
}

export function useUpdateExploreSpec(
  cellId: CellId,
): (
  action: ExploreSpecUpdateActions,
  options?: UpdateExploreSpecOptions,
) => DispatchAOResult | undefined {
  const projectVersionEditable = useProjectVersionEditable();
  const { acquireLock } = useUpdateLock({
    cellId,
    canUnlock: projectVersionEditable ?? false,
  });

  const { dispatchAO } = useHexVersionAOContext();
  const getExploreSpec = useCellContentsGetter({
    selector: (contents) => {
      if (contents.__typename !== "ExploreCell") {
        throw new Error(`Expected ExploreCell, got ${contents.__typename}`);
      }

      return {
        spec: contents.spec,
        exploreCellId: contents.exploreCellId,
      };
    },
  });

  const updateExploreSpec = useCallback(
    (action, { additionalActions = [], suppressAutoRun = false } = {}) => {
      acquireLock();
      const { exploreCellId, spec: origSpec } = getExploreSpec(cellId);
      const newSpec = exploreSpecReducer(origSpec, action);

      const actions: HexVersionAtomicOperation[] = [];
      if (!isEqual(origSpec, newSpec)) {
        actions.push(
          UPDATE_EXPLORE_CELL.create({
            cellId,
            exploreCellId,
            key: "spec",
            value: newSpec,
            suppressAutoRun,
          }),
        );
      }
      if (additionalActions.length > 0) {
        actions.push(...additionalActions);
      }

      if (actions.length > 0) {
        return dispatchAO(actions);
      }
    },
    [acquireLock, cellId, dispatchAO, getExploreSpec],
  );

  const lockStatus = useLockStatus(cellId);
  const inImportedComponent = useCellContext((c) => c.inImportedComponent);
  const editableAndUnlocked =
    lockStatus !== "other-user-locked" &&
    projectVersionEditable &&
    !inImportedComponent;

  // Not 100% necessary, but avoids HTTP requests if someone edits the webpage
  // and tries to edit a readonly explore cell
  if (!editableAndUnlocked) {
    return doNothing;
  }

  return updateExploreSpec;
}

const doNothing = () => undefined;
