import {
  CalciteType,
  ExploreChartConfig,
  ExploreDetailField,
  ExploreField,
  HqlAggregationFunction,
  calciteTypeToChartDataType,
  isPie,
  joinDatasetQueryPath,
  sentenceCase,
} from "@hex/common";

import { ExploreDropSection } from "./dnd/ExploreDndTypes.js";

export function getFieldLabel(
  field: ExploreField | ExploreDetailField,
): string {
  if ("title" in field && field.title != null) {
    return field.title;
  }
  let label = field.value;

  if ("aggregation" in field && field.aggregation != null) {
    label = `${getFieldAggregationLabel(field.aggregation)} of ${label}`;
  }
  if (field.queryPath != null && field.queryPath.length > 0) {
    label = `${label} (${joinDatasetQueryPath(field.queryPath)})`;
  }

  return label;
}

export function getFieldAggregationLabel(
  aggregation: HqlAggregationFunction,
): string {
  return aggregation === "CountDistinct"
    ? "Count distinct"
    : sentenceCase(aggregation) ?? "";
}

export function isDatetimeType(dataType: CalciteType): boolean {
  return calciteTypeToChartDataType(dataType) === "datetime";
}

export function isNumericType(dataType: CalciteType): boolean {
  return calciteTypeToChartDataType(dataType) === "number";
}

const LabelBySection: Record<ExploreDropSection["type"], string> = {
  "base-axis": "X-axis",
  "cross-axis": "Y-axis",
  "h-facet": "horizontal split",
  "v-facet": "vertical split",
  color: "color by",
  tooltip: "tooltip",
  row: "row",
  column: "column",
  value: "value",
  opacity: "opacity",
  "new-series": "new series",
  "new-group": "new Y-axis",
  aggregation: "aggregation",
  detail: "detail",
  groupby: "group by",
};

export function getLabelForChannel(
  channel: ExploreDropSection["type"],
  chartConfig?: ExploreChartConfig,
): string {
  if (chartConfig != null) {
    if (channel === "base-axis" && chartConfig.orientation === "horizontal") {
      return "Y-axis";
    }

    if (channel === "cross-axis" && isPie(chartConfig)) {
      return "Size";
    }
    if (channel === "cross-axis" && chartConfig.orientation === "horizontal") {
      return "X-axis";
    }
  }
  return LabelBySection[channel];
}

export const EXPLORE_CHART_ONLY_AGGREGATIONS: Set<HqlAggregationFunction> =
  new Set(["StdDev", "StdDevPop", "Variance", "VariancePop"]);
